
import { Options, Vue } from 'vue-class-component';
import { LocalizationServices } from '@/services/LocalizationServices';
import { LoginServices } from '@/services/LoginServices';
import NewAccountBenefits from '@/components/newAccountBenefits.vue';
import store from '@/store';
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';
import { AnonymousAuthUserClient } from '@/services/services';

@Options({
    components: {
        NewAccountBenefits
    }
})
export default class Welcome extends Vue {

    exhibitionIdentifier: string = "";
    canShow: boolean = false;

    created() {
        this.exhibitionIdentifier = this.$route.params.exhibitionIdentifier.toString();
        store.state.exhibition.identifier = this.exhibitionIdentifier;

        var exhibition = new OM.GetExhibitionVm();
        exhibition.identifier = this.exhibitionIdentifier;
        StorageServices.setExhibition(exhibition);
        StorageServices.setMainExhibition(exhibition.identifier);
    }

    mounted() {      
        setTimeout(() => {
            this.canShow = true;
        }, 2000);
    }

    continueWithoutProfile() {
        var registerAnonymous = new OM.LanguageCredential();
        registerAnonymous.preferredCulture = LocalizationServices.GetLanguage();

        AnonymousAuthUserClient.register(registerAnonymous)
        .then(x => {
            LoginServices.LoginCallback(x)
            .then(y => {
                this.$router.push("/exhibition/" +  this.exhibitionIdentifier); 
            });
        })
    }

}
